<template>
  <el-form label-width="100px">
    <el-row>
      <el-col :span="24" class="img-url">
        <el-image
          :preview-src-list="signPictures"
          v-for="(item, index) in signPictures"
          :key="index"
          :src="item"
        ></el-image>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import Form, { formCreate } from '../../../../../components/form';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      buttons: {
        submit: false,
      },
      signPictures: [],
    };
  },
  methods: {},
  async created() {
    let signPictures = [];
    signPictures = JSON.parse(this.formConfig.row.attachmentListJson);
    if (signPictures && signPictures.length) {
      this.signPictures = signPictures.map((v) => {
        const obj = v;
        return (obj.url = obj.picUrl + obj.picPath);
      });
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-image__inner,
/deep/ .el-image__error {
  width: 120px;
  height: 120px;
  margin-right: 6px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  outline: 0;
  object-fit: contain;
}

/deep/ .img-url .el-form-item__content > div {
  width: auto;
}
</style>
