<script>
import TablePage from '../../../../../components/table_page';
import Form from './form_detail.vue';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'viewImg') {
        this.formName = 'Form';
        this.formConfig = {
          type: 'view',
          row,
        };
        this.modalConfig.title = '查看图片';
        this.openDrawer();
      }
    },
  },
  async created() {
    await this.getConfigList('offApplyReport_list');
  },
};
</script>
